import React, { useEffect, useState } from 'react';
import { Flex, Text, Image, Box } from '@chakra-ui/core';

import { NavButton, AppStoreBadge } from '@zeta';
import theme, { colors } from '@theme';
import Lottie from 'react-lottie';
import { H1, H4 } from '@typography';
import ContentContainer from '../../components/ContentContainer';

import { cloudfrontBaseUrl } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import animation from '../../lotties/mobile-app-animation.json';

import JointAccountAnimation from '../../lotties/joint-account-animation.json';
import JointAccountMobileAnimation from '../../lotties/joint-account-mobile-animation.json';

const TopSection = () => {
  const [showAppStore, setShowAppStore] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      const search = window.location.search.substring(1);
      if (search.indexOf('aff=true')) {
        setShowAppStore(false);
      }
    }
  }, []);

  return (
    <Flex bg={colors.iceberg} pt={[26, 18]}>
      <ContentContainer>
        <Flex align="center" direction="column">
          <DesktopOnly>
            <Flex align="center" direction="column">
              <H1>Zeta Joint Accounts</H1>

              <H4 as="h2" mt={8}>
                Shared banking for modern couples and families
              </H4>
            </Flex>
            <Flex mt="20px" minHeight="730px">
              <Lottie
                isClickToPauseDisabled
                style={{
                  cursor: 'auto',
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: JointAccountAnimation,
                }}
              />
            </Flex>
          </DesktopOnly>

          <MobileOnly>
            <>
              <Text
                as="h1"
                textAlign="center"
                fontSize="40px"
                lineHeight="48px"
                fontWeight="600"
              >
                Zeta Joint
                <br />
                Accounts
              </Text>

              <Text
                as="h2"
                textAlign="center"
                fontSize="18px"
                fontWeight="600"
                lineHeight="22px"
                pt="22px"
              >
                Shared banking for
                <br />
                modern couples and families
              </Text>
              <Flex mt="24px" minHeight="230px" maxWidth="100%" minWidth="100%">
                <Lottie
                  width="100vw"
                  style={{
                    cursor: 'auto',
                  }}
                  isClickToPauseDisabled
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: JointAccountMobileAnimation,
                  }}
                />
              </Flex>
            </>
          </MobileOnly>
          <Flex align="center" pb={['58px', '58px']} mt={['58px', -10]} justify="center">
            <Text
              ml={4}
              fontStyle="italic"
              width="200px"
              color={colors.primary}
              fontSize="10px"
              style={{ textAlign: 'center' }}
            >
              Zeta Joint Account is a deposit account powered by Piermont Bank, Member
              FDIC.
            </Text>
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default TopSection;
