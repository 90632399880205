import React from 'react';
import { Flex, Image } from '@chakra-ui/core';

import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import { colors } from '@theme';
import TopSection from '../components/jointAccount/TopSection';
import Metadata from '../components/jointAccount/helmet';
import BankingForTwo from '../components/jointAccount/bankingForTwo';
import CardFeatures from '../components/jointAccount/cardFeatures';
import HowItWorks from '../components/jointAccount/howItWorks';
import FAQs from '../components/jointAccount/FAQs';
import Anchor from '../components/jointAccount/anchor';
import { cloudfrontBaseUrl, JAFAQContent, JAHelpfulResources } from '../lib/consts';
import { H2, H4 } from '@typography';
import ContentResource from '../components/home/ContentResource';
import GradientBackground from '../assets/JAFamiliesLoveBgDesktop.png';
import GradientBackgroundMobile from '../assets/JAFamiliesLoveBgMobile.png';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';
import VIP from '../components/jointAccount/VIP';

const JointAccount = props => {
  const FamiliesContent = () => (
    <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
      <Flex my={[10, 24]} direction="column">
        <Flex
          justify="center"
          align="center"
          direction="column"
          textAlign="center"
          mb={[10, 16]}
          px={[4, 0]}
        >
          <H2>Families 🖤︎ Zeta</H2>
          <H4 center mt={4}>
            Zeta's aim is to help you win, no matter what stage you are at in your
            <br />
            relationship or life. Here's what our customers have to say.
          </H4>
        </Flex>

        <Flex
          justify="center"
          alignSelf={['center', 'auto']}
          direction={['column', 'row']}
          mt={[0, 16]}
          mb={[0, 16]}
        >
          <Flex direction="column">
            <Image
              width="300px"
              src={`${cloudfrontBaseUrl}/joint-accounts/families_1.png`}
            />
            <Image
              pt={['32px', '23px']}
              width="300px"
              src={`${cloudfrontBaseUrl}/joint-accounts/families_2.png`}
            />
          </Flex>
          <Flex pt={['32px', 0]} pl={[0, '29px']} direction="column">
            <Image
              width="300px"
              src={`${cloudfrontBaseUrl}/joint-accounts/families_3.png`}
            />
          </Flex>
          <Flex pl={[0, '25px']} direction="column">
            <Image
              pt={['32px', 0]}
              width="300px"
              src={`${cloudfrontBaseUrl}/joint-accounts/families4.png`}
            />
            <Image
              pt={['32px', '7px']}
              width="300px"
              src={`${cloudfrontBaseUrl}/joint-accounts/families_5.png`}
            />
          </Flex>
        </Flex>
      </Flex>
    </ContentContainer>
  );

  return (
    <Layout mode="light" newNav noRootContainer>
      <Metadata />
      <Flex
        className="main-column"
        width="100%"
        justifyContent="center"
        direction="column"
        pt={[0, 16]}
      >
        <TopSection />

        <Flex mb={['50px', '82px']} justify="center">
          <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
            <BankingForTwo />
          </ContentContainer>
        </Flex>

        <VIP />

        <Flex bg={colors.iceberg} className="theres-even-more-to-love">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <CardFeatures />
          </ContentContainer>
        </Flex>

        <DesktopOnly>
          <Flex
            style={{
              backgroundImage: `url(${GradientBackground})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className="families-love-zeta"
          >
            <FamiliesContent />
          </Flex>
        </DesktopOnly>
        <MobileOnly>
          <Flex
            style={{
              backgroundImage: `url(${GradientBackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className="families-love-zeta"
            pb={['48px', '200px']}
          >
            <FamiliesContent />
          </Flex>
        </MobileOnly>

        <Flex justify="center" className="how-it-works">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <HowItWorks variant="joint-accounts" />
          </ContentContainer>
        </Flex>

        <Flex bg={colors.background} className="FAQs">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <FAQs content={JAFAQContent} />
          </ContentContainer>
        </Flex>

        <Flex bg={colors.white} className="helpful-resources">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <Flex py={[10, 24]} direction="column" justify="center" align="center">
              <Flex
                justify="center"
                align="center"
                direction="column"
                textAlign="center"
                mb={[10, 16]}
                px={[4, 0]}
              >
                <H2>More helpful resources</H2>
                <DesktopOnly>
                  <H4 mt={4} center>
                    Some useful information about how joint bank accounts work:
                  </H4>
                </DesktopOnly>
                <MobileOnly>
                  <H4 mt={4} center>
                    Some useful information about how
                    <br />
                    joint bank accounts work:
                  </H4>
                </MobileOnly>
              </Flex>

              <Flex
                pt={[6, '36px']}
                direction={['column', 'row']}
                width={['100%', '90%']}
                justify="space-between"
              >
                {JAHelpfulResources.map(({ title, image, description, ctaUrl }) => (
                  <ContentResource
                    key={`cr-${title}`}
                    titleBreak
                    customTitleSize="24px"
                    title={title}
                    description={description}
                    imageSource={image}
                    ctaUrl={ctaUrl}
                  />
                ))}
              </Flex>
            </Flex>
          </ContentContainer>
        </Flex>

        <Anchor />
      </Flex>
    </Layout>
  );
};

export default JointAccount;
